import React, { useEffect, useState } from "react";
import { TutorLayout } from "../../../layout/tutorLayout";
import { useNavigate } from 'react-router-dom';
import { useLazyGetBookingsQuery } from "../../../service/tutorApi";
import { showError } from "../../../constants/toast";
import moment from "moment";
import Pagination from "../../../constants/Pagination";
import Loader from "../../../constants/Loader";

export default function TutorUsers() {

    const navigate = useNavigate();
    const [users, setUsers] = useState<any>([]);
    const [getBookings] = useLazyGetBookingsQuery();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);

    const onPageChange = (newPage: number) => {
        setPage(newPage);
    };
    let totalPages = Math.ceil(count / 10);


    const fetchUsers = async () => {
        setIsLoading(true);
        try {
            const res = await getBookings({ bookingStatus: 3, page: page }).unwrap();
            setIsLoading(false);
            if (res?.statusCode === 200) {
                setUsers(res?.data?.booking)
                setCount(res?.data?.totalBooking)
            }

        } catch (error: any) {
            setIsLoading(false);
            showError(error?.data?.message)
        }
    }



    useEffect(() => {
        fetchUsers()
    }, [page])
    return (
        <>
            <TutorLayout className="role-layout">
                <Loader isLoad={isLoading} />
                <main className="content">
                    <section className="uhb_spc pBookingDetail_sc">
                        <div className="conta_iner v2">
                            <div className="role_head">
                                <button className="back_arrow" onClick={() => navigate('/tutor/dashboard')}>
                                    <img src={`/static/images/back.png`} alt="Back" />
                                </button>
                                <h1 className="hd_3">Manage Users</h1>
                            </div>
                            <div className="role_body">
                                <div className="users_list gap_m">
                                    {users?.length ? (
                                        users?.map((item: any, index: number) => {
                                            return (
                                                <div className="user_item" onClick={() => navigate(`/tutor/user-detail/${item?._id}`)}>
                                                    <ul className="top">
                                                        <li>
                                                            <span>Date :</span>
                                                            <strong>{moment(item?.bookingdetails?.[0]?.date).format('DD/MM/YYYY') || "-"}</strong>
                                                        </li>
                                                        <li>
                                                            <span>Time :</span>
                                                            <strong>{moment(item?.bookingdetails?.[0]?.startTime).format('hh:mmA')} - {moment(item?.bookingdetails?.[0]?.endTime).format('hh:mmA') || "-"}</strong>
                                                        </li>
                                                    </ul>
                                                    <div className="infoBox">
                                                        <figure>
                                                            <img src={item?.parents?.image ? item?.parents?.image : `/static/images/user.png`} alt="Image" />
                                                        </figure>
                                                        <h2>{item?.parents?.name ? item?.parents.name : "-"}</h2>
                                                        <ul>
                                                            <li>
                                                                <span>Subject</span>
                                                                <strong>{item?.subjectspecializations?.[0]?.name ? item?.subjectspecializations?.[0]?.name : "-"}</strong>
                                                            </li>
                                                            {/* <li>
                                                                <span>Grade</span>
                                                                <strong>12th</strong>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        <div className="no_data">
                                            <figure>
                                                <img src="/static/images/noData.png" alt="no data found" />
                                            </figure>
                                            <p>No user found</p>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                        {users?.length ? <Pagination
                            module={users}
                            page={page}
                            onPageChange={onPageChange}
                            totalPages={totalPages}
                        /> : ""}
                    </section>
                </main>
            </TutorLayout>
        </>
    );
}
