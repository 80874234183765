import TutorCard from "../../../components/tutorCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useGetPopularTutorListingQuery } from "../../../service/parentDashboard";
import { useEffect, useState } from "react";
import { showError } from "../../../constants/toast";
import { useLazyGetQualifiedTutorQuery } from "../../../service/tutorApi";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getFromStorage } from "../../../constants/storage";
import { STORAGE_KEYS } from "../../../constants/storageKeys";

const QualifiedTutors = () => {


    const [data, setData] = useState<any>([])
    const [fetchData] = useLazyGetQualifiedTutorQuery();
    const navigate = useNavigate();
  
    const fetchDataFun = async () => {
        try {
            const res = await fetchData({}).unwrap();
            if (res?.statusCode === 200) {
                setData(res?.data)
            }
        } catch (error: any) {
            // console.log(error);

        }
    }

    useEffect(() => {
        fetchDataFun();
    }, [])


    return (
        <section className="home_tutor_sc ub_spc">
            <div className="conta_iner">
                <div className="s_head text_center">
                    <h2>Choose Your Home Tutor from 20,000+ <br /> <mark className="high_light">Qualified Tutors</mark></h2>
                </div>
                <Slider
                    className="tutor_slider"
                    loop={false}
                    infinite={false}
                    slidesToShow={4}
                    slidesToScroll={1}
                    arrows={true}
                    dots={false}
                    responsive={[
                        {
                            breakpoint: 1199,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: 575,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                centerMode: true,
                                centerPadding: "40px"
                            },
                        },
                    ]}
                >
                    {data?.tutor?.length
                        ? [...data?.tutor].slice(0, 6).map((item, index) => (
                            <div key={index}>
                                <TutorCard
                                    classCount={item?.classCount}
                                    subjects={item?.subjects}
                                    price={item?.price}
                                    rating={item?.avgRating}
                                    image={item?.image}
                                    name={item?.name}
                                    id={item?._id} />
                            </div>

                        ))
                        : undefined}
                    {data?.tutor?.length > 6 ? (
                        <div >
                            <div className="see_all">
                                <Box onClick={() => navigate('/parent/popular-tutor')} component="a">See All <ArrowForwardIosIcon /></Box>
                            </div>
                        </div>
                    ) : ("")}

                </Slider>
            </div >
        </section >
    )
}

export default QualifiedTutors;