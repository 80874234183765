export const STORAGE_KEYS = {
  token: "TOKEN",
  credentials: "CREDENTIALS",
  tempToken: "TEMP_TOKEN",
  fcmToken: "FCM_TOKEN",
  language: "Language",
  user:'USER_DATA',
  application:'app',
  bookingDetails:"BookingDetails",
  bookingSettings:"BookingSettings",
  body:"body"
};
