import { Modal } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

type Conversion = { name: string };

interface GradesProps {
    open: boolean;
    onClose: () => void;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setSelected: Dispatch<SetStateAction<Conversion[]>>;
    selected: Conversion[];
}

export default function GradesModal({
    open,
    onClose,
    setOpen,
    setSelected,
    selected
}: GradesProps) {

    const gradeOptions = [
        { label: "Pre primary(Kg/Foundation)", value: "0" },
        { label: "Primary", value: "1" },
        { label: "Middle school (O-level)", value: "2" },
        { label: "High school (A-level)", value: "3" },
        { label: "College", value: "4" },
        { label: "Other", value: "5" }
    ];

    const handleGradeChange = (value: string, checked: boolean) => {
        if (checked) {
            setSelected((prev) => {
                return Array.isArray(prev) ? [...prev, { name: value }] : [{ name: value }]
            })
        } else {
            setSelected((prev) => {
                return Array.isArray(prev) ? prev.filter(item => item.name !== value) : [];
            });
        }
    };

    const isChecked = (value: string) => {
        return selected?.some(item => item.name === value);
    };

    return (
        <Modal
            className="modal setup_modal"
            id="gradesModal"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={open}
            onClose={onClose}
        >
            <div className="modal-dialog">
                <div className="modal-body">
                    <div className="btn-close">
                        <CloseIcon onClick={() => setOpen(false)} />
                    </div>
                    <h2>Select Grades You Teach</h2>
                    <form className="form">
                        <div className="control_group">
                            <FormGroup className="checkbox_label">
                                {gradeOptions.map(option => (
                                    <FormControlLabel
                                        key={option?.value}
                                        control={
                                            <Checkbox
                                                onChange={(e) => handleGradeChange(option?.value, e.target.checked)}
                                                checked={isChecked(option?.value)}
                                            />
                                        }
                                        label={option?.label}
                                    />
                                ))}
                            </FormGroup>
                        </div>
                        <div className="form_btn">
                            <Button variant="outlined" color="primary" onClick={() => { setOpen(false); setSelected([]) }}>Cancel</Button>
                            <Button color="primary" onClick={() => setOpen(false)}>Submit</Button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
}