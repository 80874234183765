/* eslint-disable jsx-a11y/img-redundant-alt */
import { ParentLayout } from "../../../layout/parentLayout";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import FilterSidebar from "../common/filterSidebar";
import StarIcon from "@mui/icons-material/Star";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useEffect, useLayoutEffect, useState } from "react";
import Pagination from "../../../constants/Pagination";
import useManageWishList from "../../../apiHooks/useManageWishlisht";
import { getFromStorage } from "../../../constants/storage";
import { STORAGE_KEYS } from "../../../constants/storageKeys";
import LoginAlertModal from "../../../Modals/LoginAlertModal";
import { useLazyGetFilteredTutorQuery } from "../../../service/tutorApi";
import { useFilters } from "../../../context/filterContext";
import Loader from "../../../constants/Loader";

export default function ParentRecomendedTutor() {
  const navigate = useNavigate(); // hook for the navigation
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const token: any = getFromStorage(STORAGE_KEYS.token);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [getData] = useLazyGetFilteredTutorQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false); // isLoading state
  const { filters, value, time, selectedSubjects, setSelectedSubjects } =
    useFilters(); // context for the filter state
  const [data, setData] = useState<any>([]);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  // const searchParams = new URLSearchParams(location.search);

  const homeSubject = searchParams.get("subject") || "";

  console.log(homeSubject, "homeSubject");

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const subjectsFilter = selectedSubjects
        ?.map((item) => ({ name: item }))
        .filter((itex) => itex.name !== homeSubject);

      let body = {
        ...(filters?.experience
          ? { totalTeachingExperience: filters.experience }
          : null),
        ...(filters?.curriculam?.length
          ? { curriculum: filters?.curriculam }
          : null),
        ...(filters?.teachingStyle?.length
          ? { teachingStyle: filters?.teachingStyle }
          : null),
        ...(filters?.verification
          ? {
              documentVerification:
                Number(filters?.verification) === 1 ? true : false,
            }
          : null),
        ...(filters?.lang ? { teachingLanguage: Number(filters?.lang) } : null),
        ...(filters?.gender ? { gender: filters?.gender } : null),
        ...(filters?.rating ? { avgRating: filters?.rating } : null),
        ...(value[0] > 5 ? { startPrice: value[0] } : null),
        ...(value[1] > 1000 ? { endPrice: value[1] } : null),
        ...(time?.startTime ? { startTime: time?.startTime } : null),
        ...(time?.endTime ? { endTime: time?.endTime } : null),
        ...(filters?.grade?.length
          ? { classes: filters?.grade?.map((data) => ({ name: data })) }
          : null),
        ...(selectedSubjects?.length > 0 || homeSubject
          ? {
              subjects:
                subjectsFilter?.length > 0
                  ? subjectsFilter
                  : [{ name: homeSubject }],
            }
          : null),
        ...(filters?.latitude ? { latitude: filters.latitude } : null),
        ...(filters?.longitude ? { longitude: filters.longitude } : null),
      };

      console.log(body, "body ji");
      const limit = 20;
      const res = await getData({
        page,
        body,
        limit,
      }).unwrap();

      if (res?.statusCode === 200) {
        console.log(res?.data, "resssssss");
        setData(res?.data?.tutor);
        setCount(res?.data?.totalTutor);
      }
    } catch (error) {
      //      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(count, "data--->>>");

  // // API hooks
  // const { data, isError, isSuccess, isLoading } =
  //   useGetPopularTutorListingQuery({
  //     limit: 20,
  //     page: page,
  //   });

  const { handleWishList } = useManageWishList();
  // onChange handler for the page
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top after a brief delay
    }, 0);
  };

  let totalPages = Math.ceil(count / 20); // calculating the total number of pages

  const hendleCloseAlert = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    if (searchParams.get("subject")) {
      setSelectedSubjects([homeSubject]);
    }
  }, [searchParams]);

  useEffect(() => {
    fetchData();
  }, [page, searchParams]);

  console.log(data, "tutorrrrrrrrrrrrr");

  return (
    <>
      <ParentLayout className="role-layout">
        <Loader isLoad={isLoading} />
        <main className="content">
          <section className="uhb_spc pTutor_sc">
            <div className="conta_iner v2">
              <div className="role_head">
                {token ? (
                  <button
                    className="back_arrow"
                    onClick={() => navigate("/parent/search-result")}
                  >
                    <img src={`/static/images/back.png`} alt="Back" />
                  </button>
                ) : (
                  ""
                )}
                <h1 className="hd_3">Popular Tutors</h1>
              </div>

              {/* <div className="location_bar">
              

               <figure>
                  <img src={`/static/images/address_icon.svg`} alt="Icon" />
                </figure>
                <h1>New York, United States</h1>
                <Box component="a">Change</Box>
              </div> */}
              <div className="parent_dash">
                <div className="gap_m">
                  <FilterSidebar fetchData={fetchData} setPage={setPage} />
                  <div className="rt_s">
                    <div className="tutor_list gap_m">
                      {data?.length ? (
                        data?.map((item: any, index: number) => (
                          <div className="tutor_item">
                            <figure>
                              <img
                                src={
                                  item?.image && item?.image !== ""
                                    ? item?.image
                                    : "/static/images/card1.png"
                                }
                                alt="tutor image"
                              />
                              <span className="t_rating">
                                <StarIcon />{" "}
                                {item?.avgRating?.toFixed(1) || "0"}
                              </span>
                              <span
                                className="t_fav"
                                onClick={() => {
                                  token
                                    ? handleWishList(item)
                                    : setOpenAlert(true);
                                }}
                              >
                                {item?.isFav ? (
                                  <FavoriteIcon />
                                ) : (
                                  <FavoriteBorderIcon />
                                )}
                              </span>
                              {item?.documentVerification ? (
                                <span className="t_verify">
                                  <img
                                    src="/static/images/verified.png"
                                    alt="img"
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                            </figure>
                            <div
                              className="tutor_info"
                              onClick={() =>
                                navigate(`/parent/tutor-detail/${item?._id}`)
                              }
                            >
                              <h2>{item?.name || ""}</h2>
                              <p>
                                {item?.classCount
                                  ? item.classCount > 99
                                    ? "99+ classes"
                                    : `${item.classCount} ${item.classCount > 1 ? "classes" : "class"}`
                                  : ""}
                              </p>
                              <p>{item?.subjects?.join(" , ")}</p>
                              <ins>${item?.price || ""}/hour</ins>
                            </div>
                          </div>
                        ))
                      ) : isLoading ? (
                        ""
                      ) : (
                        <h3 style={{ textAlign: "center" }}>No Tutor found</h3>
                      )}
                    </div>
                    {data?.length > 0 && (
                      <Pagination
                        module={data}
                        page={page}
                        onPageChange={onPageChange}
                        totalPages={totalPages}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </ParentLayout>
      <LoginAlertModal
        open={openAlert}
        setOpen={setOpenAlert}
        onClose={hendleCloseAlert}
        msg="Please login before adding tutor in to your wishlist"
      />
    </>
  );
}
