import { END_POINTS } from "../constants/url";
import { ChatData, chatHistoryResponse } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

export const chatApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getChatList: builder.query<
      CommonResponseType & { data: ChatData },
      { page: number }
    >({
      query: ({ page }) => ({
        url: `${END_POINTS.chatList}?page=${page}`,
        method: "GET",
      }),
      keepUnusedDataFor: 300,
      providesTags: (result, error, { page }) =>
        result ? [{ type: "ChatList", page }] : [],
      // merge: (currentCache, newItems) => {
      //   currentCache.data.chat.push(...newItems?.data?.chat)
      // },
      // forceRefetch({ currentArg, previousArg }) {
      //   return currentArg !== previousArg
      // },
    }),
    getChatHistory: builder.query<
      CommonResponseType & { data: chatHistoryResponse },
      { page: number,connectionId:string }
    >({
      query: ({ page,connectionId }) => ({
        url: `${END_POINTS.chating}/${connectionId}?page=${page}&limit=${20}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetChatListQuery,
  useLazyGetChatListQuery,
  useLazyGetChatHistoryQuery,
} = chatApi;
