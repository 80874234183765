/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { ParentLayout } from "../../../layout/parentLayout";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useGetStudyMaterialQuery } from "../../../service/parentDashboard";

export default function ParentStudyMaterial() {
  const navigate = useNavigate(); // navigation hook

  //API Hooks
  const {
    data: studyMaterial,
    isLoading,
    isError,
    isSuccess,
  } = useGetStudyMaterialQuery();



  return (
    <>
      <ParentLayout className="role-layout">
        <main className="content">
          <section className="uhb_spc pStudy_sc">
            <div className="conta_iner v2">
              <div className="role_head">
                <button
                  className="back_arrow"
                  onClick={() => navigate("/parent/search-result")}
                >
                  <img src={`/static/images/back.png`} alt="Back" />
                </button>
                <h1 className="hd_3">My Study Material</h1>
              </div>
              <div className="role_body">
                <div className="material_list gap_m">
                  {studyMaterial?.data?.material?.length ? (
                    studyMaterial.data.material.map((material, index) => (
                      <div className="material_item" key={index}>
                        <figure>
                          <img
                            src={`/static/images/pdf_icon2.svg`}
                            alt="PDF Icon"
                          />
                        </figure>
                        <h2>{material.title}</h2>
                        <ul>
                          {/* You can add specific list items here, for example: */}
                          {/* <li>{material.someProperty}</li> */}
                          {/* <li>{material.anotherProperty}</li> */}
                        </ul>
                        {/* Uncomment and complete if you need to show the tutor name */}
                        {/* <p>Tutor - {material.tutorName}</p> */}
                        <Button onClick={() => window.open(material?.content, '_blank', 'noopener,noreferrer')}>
                          <img
                            src={`/static/images/download_icon.svg`}
                            alt="Download Icon"
                          />{" "}
                          Download
                        </Button>
                      </div>
                    ))
                  ) : (
                    <div className="no_data">
                      <figure>
                        <img src="/static/images/noData.png" alt="no data found" />
                      </figure>
                      <p>No study material available</p>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </section>
        </main>
      </ParentLayout>
    </>
  );
}
