import React, { useEffect } from "react";
import { Layout } from "../../layout";
import TopBanner from "../../layout/Home/TopBanner";
import Categories from "../../layout/Home/categories";
import WhyChooseUs from "../../layout/Home/whyChooseUs";
import Testimonials from "../../layout/Home/Testimonials";
import SkillsTutors from "../../layout/Home/skills";
import Download from "../../layout/Home/download";
import QualifiedTutors from "../../layout/Home/qualifiedTopTutors";

export default function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Layout>
        <main className="content">
          <TopBanner />
          <Categories />
          <QualifiedTutors />
          <WhyChooseUs />
          <SkillsTutors />
          <Testimonials />
          <Download />
        </main>
      </Layout>
    </>
  );
}
