import React, { useEffect, useState } from "react";
import { TutorLayout } from "../../../layout/tutorLayout";
import { useNavigate } from 'react-router-dom';
import { Rating } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import { useLazyGetReviewsQuery } from "../../../service/tutorApi";
import { showError } from "../../../constants/toast";
import Loader from "../../../constants/Loader";
import moment from "moment";

export default function TutorReviews() {

    const navigate = useNavigate();

    const [value, setValue] = React.useState<number | null>(4);
    const [getReviewsApi, { isLoading }] = useLazyGetReviewsQuery();
    const [reviews, setReviews] = useState<any>();

    const fetchReviews = async () => {
        try {
            const res = await getReviewsApi({}).unwrap();
            if (res?.statusCode === 200) {
                setReviews(res?.data)
            }
        } catch (error: any) {
            showError(error?.data?.message)
        }
    }

    useEffect(() => {
        fetchReviews();
    }, []);


    return (
        <>
            <TutorLayout className="role-layout">
                <Loader isLoad={isLoading} />
                <main className="content">
                    <section className="uhb_spc tReviews_sc">
                        <div className="conta_iner v2">
                            <div className="role_head">
                                <button className="back_arrow" onClick={() => navigate('/tutor/dashboard')}>
                                    <img src={`/static/images/back.png`} alt="Back" />
                                </button>
                                <h1 className="hd_3">Manage Reviews</h1>
                            </div>
                            <div className="role_body">
                                <div className="total_review">
                                    <div className="lt_s">
                                        <h2><strong>{(reviews?.rating?.[0]?.avgRating || 0).toFixed(1)}</strong> </h2>
                                        <Rating name="read-only" value={Number(reviews?.rating?.[0]?.avgRating)} readOnly emptyIcon={<StarIcon />} />
                                    </div>
                                    <p>{reviews?.ratingUsers || "0"} Ratings</p>
                                </div>
                                <div className="card_box">
                                    <div className="cardBox_body">
                                        <ul className="review_list v2">
                                            {reviews?.ratingDetail?.length ? (
                                                reviews?.ratingDetail?.map((item: any) => {
                                                    return (
                                                        <li>
                                                            <figure><img src={item?.parents?.image ? item?.parents?.image : ` /static/images/user.png`} alt="icon" /></figure>
                                                            <h3>{item?.parents?.name ? item?.parents?.name : `-`} <span>{moment(item?.createdAt).format('DD/MM/YYYY') || "-"}</span></h3>
                                                            <Rating name="read-only" value={item?.rating} readOnly emptyIcon={<StarIcon />} />
                                                            <p>{item?.review || ""}</p>
                                                        </li>
                                                    )
                                                })
                                            ) : (
                                                <div className="no_data">
                                                    <figure>
                                                        <img src="/static/images/noData.png" alt="no data found" />
                                                    </figure>
                                                    <p>No reviews found</p>
                                                </div>
                                            )}

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </TutorLayout>
        </>
    );
}
