/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { TutorLayout } from "../../../layout/tutorLayout";
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, SelectChangeEvent, Button } from "@mui/material";
import WithdrawModal from "../../../Modals/withdraw";
import { useLazyGetTutorDashboardQuery } from "../../../service/tutorApi";
import { showError } from "../../../constants/toast";
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import Loader from "../../../constants/Loader";
import moment from "moment";

export default function TutorEarnings() {

    const navigate = useNavigate();

    const [open1, setOpen1] = useState(false);
    const handleCloseModal1 = () => {
        setOpen1(false);
    };
    const currentDate = new Date();
    const [dashboardApi] = useLazyGetTutorDashboardQuery();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [dashData, setDashData] = useState<any>();
    const [history, setHistory] = useState<any>([]);
    const [yearType, setYearType] = React.useState<string>('daily');
    const handleChange = (event: SelectChangeEvent) => {
        setYearType(event.target.value as string);
    };
    const [graphData, setGraphData] = useState<any>([]);

    const fetchDashboard = async () => {
        try {
            setIsLoading(true)
            const res = await dashboardApi({ type: yearType }).unwrap();
            setIsLoading(false)
            if (res?.statusCode === 200) {
                setDashData(res?.data)
                setGraphData(res?.data?.revenuesGraph)
                setHistory(res?.data?.booking?.data)
            }
        } catch (error: any) {
            setIsLoading(false)
            showError(error?.data?.message)
        }
    }


    function convertToInternationalCurrencySystem(labelValue: number) {
        return Math.abs(Number(labelValue)) >= 1.0e9
            ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
            : // Ssix Zeroes for Millions
            Math.abs(Number(labelValue)) >= 1.0e6
                ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
                : // Three Zeroes for Thousands
                Math.abs(Number(labelValue)) >= 1.0e3
                    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
                    : Math.abs(Number(labelValue));
    }



    const weekdays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

    const processedGraphData = graphData.map((item: any) => ({
        ...item,
        name: weekdays.includes(item.name) ? item.name.slice(0, 3) : item.name,

    }));



    const chartSetting = {
        yAxis: [
            {
                label: 'Earnings($)',
                tickLabelVisible: false,
                tickVisible: false,
            },
        ],
        series: [
            {
                dataKey: 'value',
                color: 'url(#gradientColor)', // Apply the gradient here
                radius: 40, // Apply radius to the bars
                convertToInternationalCurrencySystem,
            },
        ],
        height: 300,
        sx: {
            // [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
            //     transform: 'translateX(-10px)',
            // },
            [`& .${axisClasses.directionY} .${axisClasses.tickLabel}`]: {
                display: 'none',
            },
            [`& .${axisClasses.directionY} .${axisClasses.tick}`]: {
                display: 'none',
            },
        },
    };


    useEffect(() => {
        fetchDashboard();
    }, [yearType]);

    console.log(history, "histry");

    return (
        <>
            <TutorLayout className="role-layout">
                <Loader isLoad={isLoading} />
                <main className="content">
                    <section className="uhb_spc tEarnings_sc">
                        <div className="conta_iner v2">
                            <div className="role_head">
                                <button className="back_arrow" onClick={() => navigate('/tutor/dashboard')}>
                                    <img src={`/static/images/back.png`} alt="Back" />
                                </button>
                                <h1 className="hd_3">Manage Earnings</h1>
                            </div>
                            <div className="role_body">
                                <div className="dashboard_flex gap_m">
                                    <div className="lt_s">
                                        <div className="white_box">
                                            <div className="withdraw_row">
                                                <figure><img src={`/static/images/coin_icon.svg`} alt="" /></figure>
                                                <p>
                                                    <span>Total Earn</span>
                                                    <strong> {dashData?.earn?.earning !== undefined
                                                        ? dashData?.earn?.earning >= 1000000
                                                            ? `$ ${(dashData?.earn?.earning / 1000000).toFixed(
                                                                1
                                                            )} m`
                                                            : "$"+ convertToInternationalCurrencySystem(dashData?.earn?.earning).toLocaleString()
                                                        : "0"}</strong>
                                                </p>
                                                <Button onClick={() => setOpen1(true)}>Withdraw</Button>
                                            </div>
                                        </div>
                                        <div className="white_box">
                                            <div className="head">
                                                <div className="lt">
                                                    <h2>Revenue Report</h2>
                                                    <p>{moment(currentDate).format('ddd, Do MMM, YY')}</p>
                                                </div>
                                                <div className="rt">
                                                    <div className="control_group">
                                                        <Select
                                                            labelId="yearType-label"
                                                            id="yearType"
                                                            value={yearType}
                                                            onChange={handleChange}
                                                        >
                                                            <MenuItem value="daily">Daily</MenuItem>
                                                            <MenuItem value="weekly">Weekly</MenuItem>
                                                            <MenuItem value="monthly">Monthly</MenuItem>
                                                            <MenuItem value="yearly">Yearly</MenuItem>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex' }}>
                                                <svg width="0" height="0">
                                                    <defs>
                                                        <linearGradient id="gradientColor" x1="0%" y1="100%" x2="0%" y2="0%">
                                                            <stop offset="0%" style={{ stopColor: '#076221', stopOpacity: 1 }} />
                                                            <stop offset="100%" style={{ stopColor: '#05A633', stopOpacity: 1 }} />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                                <BarChart
                                                    dataset={processedGraphData ? processedGraphData : []}
                                                    xAxis={[
                                                        {
                                                            scaleType: 'band',
                                                            dataKey: 'name',
                                                            tickPlacement: 'middle',
                                                            tickLabelPlacement: 'tick',
                                                        },
                                                    ]}
                                                    {...chartSetting}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rt_s">
                                        <div className="white_box">
                                            <div className="head">
                                                <div className="lt">
                                                    <h2>Payment History</h2>
                                                </div>
                                            </div>
                                            <ul className="payment_list">
                                                {history?.length ? (
                                                    history?.map((item: any, index: number) => {
                                                        return (
                                                            <li>
                                                                <figure>
                                                                    <img src={item?.parents?.image ? item?.parents?.image : `/static/images/user.png`} alt="Image" />
                                                                </figure>
                                                                <p>
                                                                    <strong>{item?.parents?.name || "-"}</strong>
                                                                </p>
                                                                <p>
                                                                    <span>Received</span>
                                                                    <strong>${item?.totalPrice !== undefined
                                                                        ? item?.totalPrice >= 1000000
                                                                            ? `$ ${(item?.totalPrice / 1000000).toFixed(
                                                                                1
                                                                            )} m`
                                                                            : convertToInternationalCurrencySystem(item?.totalPrice).toLocaleString()
                                                                        : "0"}</strong>
                                                                </p>
                                                            </li>
                                                        )
                                                    })
                                                ) : (null)}


                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </TutorLayout>

            <WithdrawModal
                open={open1}
                onClose={handleCloseModal1}
                setOpen={setOpen1}
                earnings={dashData?.earn?.earning}
            />
        </>
    );
}
